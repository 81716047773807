<template>
  <section class="mcia-tabs-section">
    <mds-tabs responsive class="mcia-tabs-section__container" :size="config.tabSize">
      <mds-tabs-item
        v-for="tab in tabs"
        class="mcia-tabs-section__item"
        :id="'mcia-tabs-section__item-'+tab.id"
        :class="{'mcia-tabs-section__item-disabled':  tab.disabled}"
        :key="tab.id"
        :text="tab.label"
        :title="tab.label"
        :active="activeTabId === tab.id"
        @mds-tabs-item-active="(event) => selectedTab(event, tab)"
      >
        {{ tab.label }}
      </mds-tabs-item>
    </mds-tabs>
  </section>
</template>

<script lang="js">
import every from 'lodash/every';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import { findIndex } from 'lodash';

export default {
  name: 'TabsSection',
  components: {
    MdsTabs,
    MdsTabsItem,
  },
  props: {
    config: {
      type: Object,
      required: true,
      validator(value) {
        return value.tabs
          && Array.isArray(value.tabs)
          && every(value.tabs, (tab) => tab?.id && tab?.label);
      },
    },
    autoActiveTabId: {
      type: [String, Boolean],
    },
  },
  data() {
    return {
      activeTabId: this.autoActiveTabId
        ? this.config.tabs?.find((tab) => tab.id === this.autoActiveTabId)?.id
        : this.config.tabs[0]?.id,
    };
  },
  computed: {
    tabs() {
      return this.config?.tabs;
    },
  },
  watch: {
    autoActiveTabId() {
      this.activeTabId = this.autoActiveTabId;
    },
  },
  methods: {
    selectedTab(event, tab) {
      if (tab.id == this.activeTabId || tab?.disabled) {
        return;
      }
      const activeTabIndex = findIndex(this.config.tabs, (tabItem) => tabItem.id === this.activeTabId);
      if (!this.config.tabs[activeTabIndex].switchAllowed){
        this.$emit('show-tab-dialog', this.config.tabs[activeTabIndex].switchDialog, tab.id);
        return;
      }
      this.activeTabId = tab.id;
      this.$emit('selected-tab', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.mcia-tabs-section {
  &__container {
    margin-bottom: $mds-space-2-x;
  }

  &__item {
    &-disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}
</style>
