<template>
  <mds-layout-grid class="research-form">
    <mds-row>
      <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
        <mds-input v-bind="formData.title" @input="formData.title.error=false" v-model="formData.title.value" :disabled="isFormDisabled" />
      </mds-col>
    </mds-row>

    <mds-row>
      <mds-col :cols="12" :cols-at-m="6" class="research-form-input-field">
        <mds-textarea v-bind="formData.introduction" v-model="formData.introduction.value" :disabled="isFormDisabled" @input="formData.introduction.error=false" />
      </mds-col>
      <mds-col :cols="12" :cols-at-m="6" class="research-form-input-field">
        <mds-textarea v-bind="formData.description" v-model="formData.description.value" :disabled="isFormDisabled" @input="formData.description.error=false" />
      </mds-col>
    </mds-row>

    <mds-row>
      <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
        <mds-textarea v-bind="formData.keyPoints" v-model="formData.keyPoints.value" :disabled="isFormDisabled" @input="formData.keyPoints.error=false" />
      </mds-col>
    </mds-row>

    <mds-row>
      <mds-col :cols="12" :cols-at-m="6" >
        <mds-fieldset class="research-form-input-field" >
          <mds-date-picker
            v-bind="formData.publishDate"
            v-model="formData.publishDate.value"
            :min-max-dates="{min: new Date(2000, 0, 1), max: new Date(2099, 8, 15)}"
            :date-format="{day:'2-digit', month: '2-digit'}"
            :disableDates="disableFutureDates"
            :disabled="isFormDisabled"
            :displayTop= true
            onkeydown="return false"
            onpaste="return false"
            @input="formData.publishDate.error = false"
          ></mds-date-picker>
        </mds-fieldset>
      </mds-col>
      <mds-col :cols="12" :cols-at-m="6">
        <mds-fieldset class="research-form-input-field">
          <mds-select v-bind="formData.type" v-model="formData.type.value" :disabled="isFormDisabled" @input="formData.type.error=false"/>
        </mds-fieldset>
      </mds-col>
    </mds-row>

    <mds-row v-if="isEditMode">
      <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
        <mds-input
          v-bind="formData.researchFile"
          :value="formData.researchPaperUrl"
          :disabled="isFormDisabled"
          @click="enableFileControl"
        />
      </mds-col>
    </mds-row>
    <mds-row v-else>
      <mds-col :cols="12" :cols-at-m="12" class="research-form-input-field">
        <mds-input
          v-bind="formData.researchFile"
          @change="selectFile"
          type="file"
          :disabled="isFormDisabled"
          @input="formData.researchFile.error = false"
        />
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";
import MdsSelect from "@mds/select";
import MdsTextarea from "@mds/textarea";
import MdsDatePicker from '@mds/date-picker'

const ADD_RESEARCH_FORMID = "add-research";
const DELETE_RESEARCH_FORMID = "delete-research";

export default {
  name: "ResearchForm",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsInput,
    MdsFieldset,
    MdsSelect,
    MdsTextarea,
    MdsDatePicker,
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    formId: {
      type: String,
      default: 'add-research',
      required: true,
    },
  },
  data() {
    return {
      isFormDisabled: false,
      isEditMode: false,
    };
  },
  watch: {
    formId: {
      immediate: true,
      handler(value) {
        if (value != ADD_RESEARCH_FORMID) {
          this.isEditMode = true
        }
        if (value == DELETE_RESEARCH_FORMID) {
          this.isFormDisabled = true;
        }
      },
    },
  },
  methods: {
    selectFile: function (event) {
      this.$emit("select-file", event);
    },
    disableFutureDates(date) {
      const today = new Date();
      return date > today;
    },
    enableFileControl(){
      this.isEditMode = false
    }
  }
};
</script>

<style scoped lang="scss">
.research-form {
  padding: 10px 0 0 0;
  &-input-field {
    margin: $mds-space-1-x 0;
    ::v-deep {
      .mds-label .mds-label__text {
        margin-bottom: $mds-space-1-x;
      }
    }
  }
}
</style>
