<template>
  <div class="research-home">
    <research-dialog
      :dialogConfig="dialogConfig"
      :showDialog="showResearchDialog"
      @dialog-success="handleDialogSuccess"
      @dialog-cancel="handleDialogCancel"
      @dialog-dismissed="handleDialogDismiss"
    ></research-dialog>
    <tabs-section
      :config="tabsConfig"
      :autoActiveTabId="selectedTab.id"
      class="research-home-tab-outer"
      @selected-tab="onSelectedTab"
      @show-tab-dialog="HandleTabSwitchDialog"
    >
    </tabs-section>
    <add-research
      v-if="selectedTab.component === 'add-research'"
      @tab-switch="handleTabSwitch"
    />
    <edit-research
      v-if="selectedTab.component === 'edit-research'"
      @tab-switch="handleTabSwitch"
    />
    <delete-research
      v-if="selectedTab.component === 'delete-research'"
      @tab-switch="handleTabSwitch"
    />
  </div>
</template>

<script lang="js">
import researchConfig from '@/data/publishResearch.json';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import { findIndex } from 'lodash';
import { computed } from '@vue/composition-api';
import AddResearch from '../components/PublishResearch/AddResearch.vue';
import EditResearch from '../components/PublishResearch/EditResearch.vue';
import DeleteResearch from '../components/PublishResearch/DeleteResearch.vue';
import TabsSection from '@/components/Common/TabsSection/TabsSection';
import ResearchDialog from '../components/PublishResearch/ResearchDialog.vue';

export default {
  name: 'PublishResearch',
  components: {
    AddResearch,
    EditResearch,
    DeleteResearch,
    TabsSection,
    ResearchDialog,
  },
  data() {
    return {
      tabsConfig: cloneDeep(researchConfig?.tabsConfig),
      selectedTab: {},
      showResearchDialog: false,
      dialogConfig: {},
      switchTabId: ''
    }
  },
  computed: {
    ...mapGetters({
      isAdminUser: "user/isAdminUser",
    }),
  },
  mounted() {
    if (this.isAdminUser) {
      this.tabsConfig.tabs.forEach((tab, index) => {
        tab.disabled = false;
        this.$set(this.tabsConfig.tabs, index, tab);
      });
    }
    this.redirectToTab('add-research');
  },
  provide() {
    return {
      selectedTab: computed(() => this.selectedTab.id),
    };
  },
  methods: {
    onSelectedTab(tab) {
      this.selectedTab = tab;
    },
    redirectToTab(tabId) {
      this.showResearchDialog = false;
      const currentIndex = findIndex(this.tabsConfig.tabs, (tabItem) => tabItem.id === tabId);
      this.onSelectedTab(this.tabsConfig.tabs[currentIndex]);
    },
    handleTabSwitch(tabId, value){
      const tabIndex = findIndex(this.tabsConfig.tabs, (tabItem) => tabItem.id === tabId);
      const tab = this.tabsConfig.tabs[tabIndex]
      tab.switchAllowed = value
      this.$set(this.tabsConfig.tabs, tabIndex, tab);
    },
    HandleTabSwitchDialog(dialogConfig, tabId){
      this.dialogConfig = dialogConfig;
      this.showResearchDialog = true;
      this.switchTabId = tabId
    },
    handleDialogCancel() {
      this.showResearchDialog = false;
    },
    handleDialogDismiss() {
      this.showResearchDialog = false;
    },
    handleDialogSuccess(tabId) {
      const tabIndex = findIndex(this.tabsConfig.tabs, (tabItem) => tabItem.id === tabId);
      const tab = this.tabsConfig.tabs[tabIndex]
      tab.switchAllowed = true
      this.$set(this.tabsConfig.tabs, tabIndex, tab);
      this.redirectToTab(this.switchTabId);
    },
  },
};
</script>

<style lang="scss">
.research-home {
  max-width: 960px;
  margin: 0 auto;
  &-tab-outer {
    border: none;
    margin-top: 20px;
    .mds-tabs{
      border: none;
    }
    .mds-tabs__item{
      &--active {
        box-shadow: inset 0 -4px 0 0 #FF0000;
        .mds-button{
          font-weight: 700 ;
          color: #000 ;
        }
      }
    }
  }
}
</style>
