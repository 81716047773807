import Http from "./api";


export const sendMail = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/email/send`;

  return Http.post(url, postData).then((response) => {
    return response.data;
  });
};
