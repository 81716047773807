<template>
  <mds-dialog
    v-model="showDialog"
    :title="dialogConfig.dialogTitle"
    :text="dialogConfig.dialogText"
    @mds-dialog-dismissed="resetDialog"
    >
    <template #mds-dialog-actions-right>
      <mds-button-container right-aligned>
        <mds-button
          type="button"
          variation="secondary"
          @click="handleDialogCancel"
        >
          {{ dialogConfig.cancelBtnText }}
        </mds-button>
        <mds-button
          type="button"
          variation="primary"
          @click="handleDialogSuccess"
        >
          {{ dialogConfig.successBtnText }}
        </mds-button>
      </mds-button-container>
    </template>
  </mds-dialog>
</template>

<script>
import MdsDialog from '@mds/dialog';
import { MdsButton, MdsButtonContainer } from "@mds/button";

export default {
  name: "ResearchDialog",
  components: {
    MdsDialog,
    MdsButton,
    MdsButtonContainer,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    dialogConfig: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    }
  },
  methods: {
    handleDialogCancel() {
      this.$emit("dialog-cancel");
    },
    handleDialogSuccess() {
      this.$emit("dialog-success", this.dialogConfig?.action);
    },
    resetDialog() {
      this.$emit("dialog-dismissed");
    },
  }
}
</script>

<style>

</style>